import React, { useState, useEffect, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Snackbar, Alert, Slide } from "@mui/material";

/***** ` Utils....` *****/
import { actions } from "./redux/store/store";
import { useGetCurrentUserQuery } from "./api/auth";

/***** ` Modals....` *****/
import DriverModal from "../src/components/shared/customModal/driver";
import StoreModal from "../src/components/shared/customModal/store";
import TransactionsModal from "../src/components/shared/customModal/transactions";
import ReasonModal from "../src/components/shared/customModal/reason";
import OrderModal from "../src/components/shared/customModal/order";
import ShiftTemplateModal from "../src/components/shared/customModal/shiftTemplate";
import AdhocPaymentsModal from "../src/components/shared/customModal/adhocPayment";
import DirectPaymentsModal from "../src/components/shared/customModal/directPayment";
import MetroModal from "./components/shared/customModal/metros";
import NotificationModal from "./components/shared/customModal/notification";
import NotesModal from "./components/shared/customModal/notesModal";
import EmailModal from "./components/shared/customModal/email";
import OrderLogsDetailsModal from "./components/shared/orderHelper/OrderLogs";
import ShiftPage from "./views/pages/Shift-Page";
import CompanyMainModal from "./components/shared/customModal/company";
import CoachingModal from "./components/shared/customModal/coaching";

/***** `Components....` *****/
import AuthHandler from "../src/auth/AuthHandler";
import Menu from "../src/components/common/Menu";
import Header from "../src/components/common/Header";
// import Topbar from "../src/components/shared/topbar";
// import DirectPayment from "./components/DirectPayment";

/***** ` Styles....` *****/
import "../src/styles/app.scss";

/***** ` Pages....` *****/
import AdhocPayment from "./views/pages/AdhocPayment";
import Confirmation from "../src/components/Confirmation";
import Drivers from "../src/views/pages/Drivers";
import Deposit from "./views/pages/Deposit";
import DriversEarning from "./views/pages/Drivers-Earning";
import Email from "./views/pages/Email";
import ForgotPassword from "./views/pages/ForgotPassword";
import Login from "./views/pages/Login";
import LinksToDocs from "../src/views/pages/LinksToDocs";
import Metros from "./views/pages/Metros";
import Notifications from "./views/pages/Notifications";
import Orders from "./views/pages/Orders";
import Payments from "../src/components/Payments";
import Register from "./views/pages/Register";
import ResetPassword from "../src/components/ResetPassword";
import ShiftTemplate from "../src/components/ShiftTemplate";
import Stores from "../src/components/Stores";
import Scheduling from "./views/pages/Scheduling";
import Transactions from "./views/pages/Transactions-Report";
import Users from "../src/components/Users";
import { MuiLoader } from "./components/common/loading/Loader";
import DriverReport from "./views/pages/DriverReport";
import PiplineStage from "./views/pages/Pipline-Stage";
import Operator from "./views/pages/Operator";
import CompanyModal from "./components/shared/customModal/companyModal";
import Company from "./views/pages/Company";
import Feedback from "./views/pages/Feedback";
import PhoneModal from "./components/shared/customModal/phoneModal";
import ScheduleModal from "./components/shared/customModal/scheduleModal";
import DriverCoach from "./views/pages/DriverCoach";
import CoachingHistoryModal from "./components/shared/customModal/coaching/CoachingHistory";
import AddExceptionModal from "./components/shared/customModal/order/AddExceptionModal";
import Ratings from "./components/shared/customModal/coaching/Ratings";
import PaymentCalendar from "./views/pages/PaymentCalendar";
import PaymentCalendarModel from "./components/shared/customModal/paymentCalendar";
import InterestedDriverModal from "./components/shared/customModal/order/InterestedDriverModal"
import PODsModal from "./components/shared/orderHelper/PODs";
import BulkOrderModal from "./components/shared/orderHelper/BulkOrder";
import DriverLicenseModal from "./components/shared/customModal/driverLicenseModal";
import InsurancePolicyModal from "./components/shared/customModal/insurancePolicyModal";
import { HealthCheck } from "./views/pages/HealthCheck";

// import Topbar from "../src/components/shared/topbar";
// import DirectPayment from "./components/DirectPayment";
const Pipeline = lazy(() => import("./views/pages/Pipeline"));
const PipelineModal = lazy(() =>
  import("./components/shared/customModal/pipeline")
);
const SlideUp = (p) => <Slide {...p} direction="up" />;

const routes = ['/healthCheck']

function App() {
  const driver = useSelector((state) => state.modal.driver);
  const order = useSelector((state) => state.modal.order);
  const shifttemplate = useSelector((state) => state.modal.shifttemplate);
  const store = useSelector((state) => state.modal.store);
  const transaction = useSelector((state) => state.modal.transaction);
  const reason = useSelector((state) => state.modal.reason);
  const adhocpayment = useSelector((state) => state.modal.adhocpayment);
  const directpayment = useSelector((state) => state.modal.directpayment);
  const metro = useSelector((state) => state.modal.metro);
  const calendarPayment = useSelector((state) => state.modal.calendarPayment);
  const orderLogs = useSelector((state) => state.modal.orderLogs);
  const email = useSelector((state) => state.modal.email);
  const notification = useSelector((state) => state.modal.notification);
  const notes = useSelector((state) => state.modal.notes);
  const phone = useSelector((state) => state.modal.phone)
  const driverLicense = useSelector((state) => state.modal.driverLicense)
  const insurancePolicy = useSelector((state) => state.modal.insurancePolicy)
  const pipeline = useSelector((state) => state.modal.pipeline);
  const company = useSelector((state) => state.modal.company);
  const companymain = useSelector((state) => state.modal.companymain);
  const schedule = useSelector((state) => state.modal.schedule);
  const coaching = useSelector((state) => state.modal.coaching);
  const coachingHistory = useSelector((state) => state.modal.coachingHistory);
  const addException = useSelector((state) => state.modal.addException);
  const rating = useSelector((state) => state.modal.reating);
  const interesteDriver = useSelector((state) => state.modal.interestedDriver)
  const PodsModal = useSelector((state) => state.modal.pods)
  const bulkOrderModal = useSelector((state) => state.modal.bulkOrder)
  const currentUser = useSelector((state) => state.auth.currentUser)
  const isLoading = useSelector((state) => state.auth.isLoading)

  const [usersTab, setUsersTab] = useState("Current");
  const [scTab, setScTab] = useState("pipeline_drivers");
  const [stopUserQuery, setStopUserQuery] = useState(false);

  const currentUserQuery = useGetCurrentUserQuery(null, {
    skip: routes.includes(window.location.pathname),
  });
  useEffect(() => {
    if (!stopUserQuery) {
      actions.auth.setCurrentUser(currentUserQuery.data);
      actions.auth.setLoading(currentUserQuery.isLoading);
    }
  }, [currentUserQuery, stopUserQuery]);

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  return (
    <>
      <Router>
        <Suspense fallback={<MuiLoader />}>
          <AuthHandler />
          {!stopUserQuery && currentUser && (
            <>
              <Header />
              <Menu />
            </>
          )}

          <Routes>
            {!stopUserQuery && !isLoading && <>
              <Route path="/healthCheck" element={<HealthCheck />}></Route>
              <Route
                path="/login"
                element={<Login onStopUserQuery={setStopUserQuery} />}
              />
              <Route
                path="/register/:hash"
                element={<Register onStopUserQuery={setStopUserQuery} />}
              />
              <Route
                path="/confirmation/:hash"
                element={<Confirmation onStopUserQuery={setStopUserQuery} />}
              />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route
                path="/resetPassword/:hash"
                element={<ResetPassword onStopUserQuery={setStopUserQuery} />}
              />
            </>}

            {!stopUserQuery && !isLoading && currentUser && <>
              <Route path="/drivers" element={<Drivers />}></Route>
              <Route path="/shifts" element={<Orders />}></Route>
              <Route path="/shiftstemplate" element={<ShiftTemplate />}></Route>
              <Route path="/hubs" element={<Stores />}></Route>
              <Route path="/adhocpayment" element={<AdhocPayment />}></Route>
              {/* <Route path="/directpayment" element={<DirectPayment />}></Route> */}
              <Route path="/shiftpage" element={<ShiftPage />}></Route>
              {/* <Route path="/driversreport" element={<DriverReport />}></Route> */}
              <Route path="/piplinestage" element={<PiplineStage />}></Route>
              <Route path="/operator" element={<Operator />}></Route>
              <Route path="/feedback" element={<Feedback />}></Route>
              <Route path="/drivercoaching" element={<DriverCoach />}></Route>


              <Route
                path="/users/:tab"
                element={
                  <Users onSetUsersTab={setUsersTab} usersTab={usersTab} />
                }
              />
              <Route
                path="/users"
                element={
                  <Users onSetUsersTab={setUsersTab} usersTab={usersTab} />
                }
              />
              {/* <Route path="/links-to-docs" element={<LinksToDocs />}></Route> */}
              <Route path="/reports" />
              <Route
                path="/reports/drivers-earning"
                element={<DriversEarning />}
              />
              <Route path="/reports/transactions" element={<Transactions />} />
              <Route path="/deposit" element={<Deposit />}></Route>
              <Route path="/payments" element={<Payments />} />
              <Route path="/paymentsCalendar" element={<PaymentCalendar />} />
              <Route path="/metros" element={<Metros />} />
              <Route path="/emails" element={<Email />} />
              <Route path="/scheduling" element={<Scheduling />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/shifts" element={<Orders />} />
              <Route
                path="/schedulers/:type"
                element={<Pipeline onSetScTab={setScTab} usersTab={scTab} />}
              />
              <Route
                path="/schedulers"
                element={<Pipeline onSetScTab={setScTab} usersTab={scTab} />}
              />

              <Route path="*" element={<Navigate to="/hubs" />} />
              <Route path="/company" element={<Company />}></Route>
            </>}
          </Routes>
        </Suspense>
      </Router>
      <Suspense fallback={<MuiLoader />}>
        {driver.open && <DriverModal id={driver.id} />}
        {order.open && <OrderModal id={order.id} />}
        {shifttemplate.open && <ShiftTemplateModal id={shifttemplate.id} />}
        {store.open && <StoreModal id={store.id} />}
        {transaction.open && <TransactionsModal id={transaction.id} />}
        {reason.open && <ReasonModal id={reason.id} />}
        {adhocpayment.open && <AdhocPaymentsModal id={order.id} />}
        {directpayment.open && <DirectPaymentsModal id={directpayment.id} />}
        {metro.open && <MetroModal id={metro.id} />}
        {calendarPayment.open && <PaymentCalendarModel id={metro.id} />}
        {orderLogs.open && <OrderLogsDetailsModal id={orderLogs.id} />}
        {email.open && <EmailModal id={email.id} />}
        {notification.open && <NotificationModal id={notification.id} />}
        {notes.open && <NotesModal id={notes.id} />}
        {phone.open && <PhoneModal id={phone.id} />}
        {driverLicense.open && <DriverLicenseModal id={driverLicense.id} />}
        {insurancePolicy.open && <InsurancePolicyModal id={insurancePolicy.id} />}
        {pipeline.open && <PipelineModal id={pipeline.id} />}
        {company.open && <CompanyModal id={company.id} />}
        {companymain.open && <CompanyMainModal id={companymain.id} />}
        {schedule.open && <ScheduleModal id={schedule.id} />}
        {coaching.open && <CoachingModal id={coaching.id} />}
        {coachingHistory.open && <CoachingHistoryModal id={coachingHistory.id} />}
        {addException.open && <AddExceptionModal id={addException.id} />}
        {rating.open && <Ratings id={rating.id} />}
        {PodsModal.open && <PODsModal id={PodsModal.id} />}
        {bulkOrderModal.open && <BulkOrderModal id={bulkOrderModal.id} />}

        {interesteDriver.open && <InterestedDriverModal id={interesteDriver.id} />}
      </Suspense>
      {notifications.map(({ message, open, status }) => (
        <Snackbar
          key={message}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={open}
          autoHideDuration={3000}
          onClose={() => actions.notifications.close(message)}
          TransitionComponent={SlideUp}
        >
          <Alert severity={status}>{message}</Alert>
        </Snackbar>
      ))}
    </>
  );
}

export default App;
